import * as Actions from "../actions";
import * as common from "../actions/Common";

import feeService from "appServices/feeService";

export { loadFeeStatus, downloadFeeVoucher };

function loadFeeStatus(sessionId) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.FEE_STATUS_LOADING));

      const result = await feeService.getfeeStatus(sessionId);
      return dispatch(
        common.fetchSuccessWithData(Actions.FEE_STATUS_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.FEE_STATUS_ERROR));
    }
  };
}

function downloadFeeVoucher(feeInfo) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.FEE_VOUCHER_DOWNLOAD_LOADING));
      
      const result = await feeService.downloadFeeVoucher(feeInfo);
      return dispatch(
        common.fetchSuccessWithData(
          Actions.FEE_VOUCHER_DOWNLOAD_SUCCESS,
          result
        )
      );
    } catch (error) {
      debugger;
      return dispatch(
        common.fetchError(error, Actions.FEE_VOUCHER_DOWNLOAD_ERROR)
      );
    }
  };
}
