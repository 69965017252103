import * as Actions from "../actions";
import * as common from "../actions/Common";

import appraisalsService from "appServices/appraisalsService";

export{getAppraisalList}

function getAppraisalList() {
  return async dispatch => { 
    
    dispatch(common.fetchStart(Actions.APPRAISALS_LIST_LOADING));
    appraisalsService
      .getAppraisalList()
      .then(appraisalList => {
        return dispatch(common.fetchSuccessWithData(Actions.APPRAISALS_LIST_SUCCESS, appraisalList));
      })
      .catch(error => {
        debugger;
        console.error(`Error****:`, error);
        dispatch(common.fetchError(error));
      });
  };
}
