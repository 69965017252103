export const LOOKUP_CAMPUS_LOADING = "LOOKUP_CAMPUS_LOADING";
export const LOOKUP_CAMPUS_SUCCESS = "LOOKUP_CAMPUS_SUCCESS";
export const LOOKUP_CAMPUS_ERROR = "LOOKUP_CAMPUS_ERROR";
export const LOOKUP_SESSION_LOADING = "LOOKUP_SESSION_LOADING";
export const LOOKUP_SESSION_SUCCESS = "LOOKUP_SESSION_SUCCESS";
export const LOOKUP_SESSION_ERROR = "LOOKUP_SESSION_ERROR";
export const LOOKUP_CLASSS_LOADING = "LOOKUP_CLASSS_LOADING";
export const LOOKUP_CLASSS_SUCCESS = "LOOKUP_CLASSS_SUCCESS";
export const LOOKUP_CLASSS_ERROR = "LOOKUP_CLASSS_ERROR";
export const LOOKUP_SUBJ_LOADING = "LOOKUP_SUBJ_LOADING";
export const LOOKUP_SUBJ_SUCCESS = "LOOKUP_SUBJ_SUCCESS";
export const LOOKUP_SUBJ_ERROR = "LOOKUP_SUBJ_ERROR";

export const LOOKUP_DESIGNATION_LOADING = "LOOKUP_DESIGNATION_LOADING";
export const LOOKUP_DESIGNATION_SUCCESS = "LOOKUP_DESIGNATION_SUCCESS";
export const LOOKUP_DESIGNATION_ERROR = "LOOKUP_DESIGNATION_ERROR";

export const LOOKUP_CLASSESFORONLINEREGISTRATION_LOADING = "LOOKUP_CLASSESFORONLINEREGISTRATION_LOADING";
export const LOOKUP_CLASSESFORONLINEREGISTRATION_SUCCESS = "LOOKUP_CLASSESFORONLINEREGISTRATION_SUCCESS";
export const LOOKUP_CLASSESFORONLINEREGISTRATION_ERROR = "LOOKUP_CLASSESFORONLINEREGISTRATION_ERROR";

export const LOOKUP_COURSETYPE_LOADING = "LOOKUP_COURSETYPE_LOADING";
export const LOOKUP_COURSETYPE_SUCCESS = "LOOKUP_COURSETYPE_SUCCESS";
export const LOOKUP_COURSETYPE_ERROR = "LOOKUP_COURSETYPE_ERROR";

export const LOOKUP_COURSECATEGORY_LOADING = "LOOKUP_COURSECATEGORY_LOADING";
export const LOOKUP_COURSECATEGORY_SUCCESS = "LOOKUP_COURSECATEGORY_SUCCESS";
export const LOOKUP_COURSECATEGORY_ERROR = "LOOKUP_COURSECATEGORY_ERROR";

export const LOOKUP_ONLINE_CLASSES_LOADING = "LOOKUP_ONLINE_CLASSES_LOADING";
export const LOOKUP_ONLINE_CLASSES_SUCCESS = "LOOKUP_ONLINE_CLASSES_SUCCESS";
export const LOOKUP_ONLINE_CLASSES_ERROR = "LOOKUP_ONLINE_CLASSES_ERROR";

export const LOOKUP_CHECKLIST_LOADING = "LOOKUP_CHECKLIST_LOADING";
export const LOOKUP_CHECKLIST_SUCCESS = "LOOKUP_CHECKLIST_SUCCESS";
export const LOOKUP_CHECKLIST_ERROR = "LOOKUP_CHECKLIST_ERROR";
