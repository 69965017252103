import * as Actions from "../actions";
import * as common from "../actions/Common";

import theService from "appServices/syllabusService";

export { getSubjectList, getSyllabusDetails };

const getSubjectList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.SUBJECT_LIST_LOADING,
    Actions.SUBJECT_LIST_SUCCESS,
    Actions.SUBJECT_LIST_ERROR,
    theService.getSubjectList,
    query
  );

const getSyllabusDetails = (subjectId) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.SYLLABUS_DETAILS_LOADING));

    const result = await theService.getSyllabusDetails(subjectId);
    return dispatch(
      common.fetchSuccessWithData(Actions.SYLLABUS_DETAILS_SUCCESS, {
        subjectId,
        result,
      })
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.SYLLABUS_DETAILS_ERROR));
  }
};
