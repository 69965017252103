import { api } from "@mb";

const quizService = {
  getById: async (id) => api.get(`api/quiz/${id}`),

  getPagedList: async (query) => api.getPagedList(`api/quiz/paged`, query),

  getNewQuiz: async (quiz) => api.post(`api/quiz`, quiz),

  updateQuiz: async (quiz) => api.post(`api/quiz`, quiz),

  submitBulkQuizs: async (formData) =>
    api.postFormData("api/quiz/bulk", formData),

  submitQuizMedia: async (quizId, formData) =>
    api.postFormData(`api/quiz/${quizId}/questions/upload`, formData),

  getQuestionList: async (query) =>
    api.get(`api/quiz/${query.quizId}/questions`),
    
  getQuizInfoForAdmissionTest: async () => api.get(`api/quiz/ForAdmission`),
};

export default quizService;
