import * as Actions from "../actions";
import * as common from "../actions/Common";

import theService from "appServices/lookupService";

export {
	fetchCampusList,
	fetchSessionList,
	fetchClassList,
	fetchSubjectList,
	fetchDesignationList,
	fetchCourseTypeList,
	fetchCourseCategoryList,
	fetchOnlineClassList,
	fetchClassesForOnlineRegistrationList,
	fetchCheckListForAdmission
};

const LookupType = {
	Campus: 1000,
	Session: 1001,

	LatestClasses: 2000,
	ClassesBySessionId: 2001,
	ClassesForOnlineSchooling: 2002,

	LatestSubjects: 3000,
	SubjectsByClassId: 3001,

	CheckListForAdmission: 1050,
};
const ListOfValueGroups = {
  EmploymentStatus: "EmploymentStatus",
  GradeCalculationType: "GradeCalculationType",
  GradingNumberRange: "GradingNumberRange",
  GradingType: "GradingType",
  TaskStatus: "TaskStatus",
  Role: "Role",
  DiaryType: "DiaryType",
  Designation: "Designation",
  CourseType: "TOS_CourseType",
  CourseCategory: "TOS_CourseCategory",
  ClassesForOnlineRegistration: "OnlineRegistration",
};

const fetchCampusList = () => async (dispatch) =>
	await common.thunkHelper(
		dispatch,
		Actions.LOOKUP_CAMPUS_LOADING,
		Actions.LOOKUP_CAMPUS_SUCCESS,
		Actions.LOOKUP_CAMPUS_ERROR,
		theService.getList,
		{
			type: LookupType.Campus,
		}
	);
const fetchCheckListForAdmission = () => async (dispatch) =>
	await common.thunkHelper(
		dispatch,
		Actions.LOOKUP_CHECKLIST_LOADING,
		Actions.LOOKUP_CHECKLIST_SUCCESS,
		Actions.LOOKUP_CHECKLIST_ERROR,
		theService.getList,
		{
			type: LookupType.CheckListForAdmission,
		}
	);

const fetchSessionList = (campusId) => async (dispatch) =>
	await common.thunkHelper(
		dispatch,
		Actions.LOOKUP_SESSION_LOADING,
		Actions.LOOKUP_SESSION_SUCCESS,
		Actions.LOOKUP_SESSION_ERROR,
		theService.getList,
		{
			type: LookupType.Session,
			info: campusId,
		}
	);
const fetchClassList = () => async (dispatch) =>
	await common.thunkHelper(
		dispatch,
		Actions.LOOKUP_CLASSS_LOADING,
		Actions.LOOKUP_CLASSS_SUCCESS,
		Actions.LOOKUP_CLASSS_ERROR,
		theService.getList,
		{
			type: LookupType.LatestClasses,
		}
	);
const fetchOnlineClassList = () => async (dispatch) =>
	await common.thunkHelper(
		dispatch,
		Actions.LOOKUP_ONLINE_CLASSES_LOADING,
		Actions.LOOKUP_ONLINE_CLASSES_SUCCESS,
		Actions.LOOKUP_ONLINE_CLASSES_ERROR,
		theService.getList,
		{
			type: LookupType.ClassesForOnlineSchooling,
		}
	);
const fetchSubjectList = (classId) => async (dispatch) =>
	await common.thunkHelper(
		dispatch,
		Actions.LOOKUP_SUBJ_LOADING,
		Actions.LOOKUP_SUBJ_SUCCESS,
		Actions.LOOKUP_SUBJ_ERROR,
		theService.getList,
		{
			type: LookupType.LatestSubjects,
			info: classId,
		}
	);

const fetchDesignationList = () => async (dispatch) => {
	try {
		dispatch(common.fetchStart(Actions.LOOKUP_DESIGNATION_LOADING));

		const res = await theService.getLov(ListOfValueGroups.Designation);
		const result = res?.map((d) => ({ ...d, value: d.numericValue }));
		return dispatch(
			common.fetchSuccessWithData(Actions.LOOKUP_DESIGNATION_SUCCESS, result)
		);
	} catch (error) {
		debugger;
		return dispatch(common.fetchError(error, Actions.LOOKUP_DESIGNATION_ERROR));
	}
};

const fetchClassesForOnlineRegistrationList = () => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.LOOKUP_CLASSESFORONLINEREGISTRATION_LOADING));

    const res = await theService.getLov(ListOfValueGroups.ClassesForOnlineRegistration);
    const result = res?.map((d) => ({ ...d, value: d.numericValue }));
    return dispatch(
      common.fetchSuccessWithData(Actions.LOOKUP_CLASSESFORONLINEREGISTRATION_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.LOOKUP_CLASSESFORONLINEREGISTRATION_ERROR));
  }
};

const fetchCourseTypeList = () => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.LOOKUP_COURSETYPE_LOADING));
    const res = await theService.getLov(ListOfValueGroups.CourseType);
    const result = res?.map((d) => ({ ...d, value: d.numericValue }));
    
    return dispatch(
      common.fetchSuccessWithData(Actions.LOOKUP_COURSETYPE_SUCCESS, result)
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.LOOKUP_COURSETYPE_ERROR));
  }
};

const fetchCourseCategoryList = () => async (dispatch) => {
	try {
		dispatch(common.fetchStart(Actions.LOOKUP_COURSECATEGORY_LOADING));

		const res = await theService.getLov(ListOfValueGroups.CourseCategory);
		const result = res?.map((d) => ({ ...d, value: d.numericValue }));
		return dispatch(
			common.fetchSuccessWithData(Actions.LOOKUP_COURSECATEGORY_SUCCESS, result)
		);
	} catch (error) {
		debugger;
		return dispatch(
			common.fetchError(error, Actions.LOOKUP_COURSECATEGORY_ERROR)
		);
	}
};
