import { ClaimKeys } from "@mb";

export const menuList = [
  {
    key: "home",
    linkTo: "/home",
    iconClass: "icon icon-home",
    intlMessagesId: "sidebar.homePage",
    claimKey: "AllowedItem",
  },
  {
    key: "profile",
    linkTo: "/profile",
    iconClass: "icon icon-user",
    intlMessagesId: "sidebar.profilePage",
    claimKey: ClaimKeys.CanViewAllPages,
  },
  {
    key: "assessment",
    linkTo: "/assessment",
    iconClass: "icon icon-tasks",
    intlMessagesId: "sidebar.assessmentPage",
    claimKey: ClaimKeys.CanViewAllPages,
  },
  {
    key: "FeeStatus",
    linkTo: "/FeeStatus",
    iconClass: "icon icon-pricing-table",
    intlMessagesId: "sidebar.feeStatusPage",
    claimKey: "AllowedItem",
  },
  {
    key: "syllabus",
    linkTo: "/syllabus",
    iconClass: "icon icon-product-list",
    intlMessagesId: "sidebar.syllabusPage",
    claimKey: ClaimKeys.CanViewAllPages,
  },
  {
    key: "course",
    linkTo: "/course",
    iconClass: "icon icon-visits",
    intlMessagesId: "sidebar.eCoursePage",
    claimKey: ClaimKeys.CanViewAllPages,
  },
  {
    key: "tasks",
    linkTo: "/tasks",
    iconClass: "icon icon-feedback",
    intlMessagesId: "sidebar.tasksPage",
    claimKey: ClaimKeys.CanViewAllPages,
  },
  {
    key: "diary",
    linkTo: "/diary",
    iconClass: "icon icon-feedback",
    intlMessagesId: "sidebar.diaryPage",
    claimKey: ClaimKeys.CanViewAllPages,
  },
  // {
  //   key: "appraisals",
  //   linkTo: "/appraisals",
  //   iconClass: "icon icon-feedback",
  //   intlMessagesId: "sidebar.appraisalsPage"
  //,claimKey: ClaimKeys.CanViewAllPages,},
  {
    key: "library",
    linkTo: "/library",
    iconClass: "icon icon-orders",
    intlMessagesId: "sidebar.eLibraryPage",
    claimKey: ClaimKeys.CanViewAllPages,
  },
  // {
  //   key: "videos",
  //   linkTo: "/videos",
  //   iconClass: "icon icon-data-display",
  //   intlMessagesId: "sidebar.videosPage"
  //,claimKey: ClaimKeys.CanViewAllPages,}
];
