import * as Actions from "../actions";
import * as common from "../actions/Common";

import otpService from "appServices/otpService";

export function submitResetOtpMessage(){
  return dispatch=>{
    dispatch(common.fetchSuccess({type:Actions.OTP_RESET_MESSAGE}));
  }
}
export function sendOtp() {
  return async dispatch => {
    try {
      dispatch(common.fetchStart(Actions.OTP_LOADING));

      const result = await otpService.sendOtp();
      if (result && result.isValid) {
        console.log(result);
        return dispatch(
          common.fetchSuccessWithData(Actions.OTP_REQ_SENT, result)
        );
      } else {
        return dispatch(
          common.fetchSuccessWithData(Actions.OTP_REQ_FAILED, result)
        );
      }
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.OTP_ERROR));
    }
  };
}

export function verifyOtp(otp) {
  return async dispatch => {
    try {
      dispatch(common.fetchStart(Actions.OTP_VERIFYING));

      const result = await otpService.verifyOtp(otp);
      if (result && result.isValid)
        return dispatch(
          common.fetchSuccessWithData(Actions.OTP_VERIFIED, result)
        );
      else {
        return dispatch(
          common.fetchSuccessWithData(Actions.OTP_VERIFICATION_FAILED, result)
        );
      }
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.OTP_ERROR));
    }
  };
}
