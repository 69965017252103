import { Redirect, Route, Switch } from "react-router-dom";
import { useClaims, ClaimKeys } from "@mb";

import asyncComponent from "util/asyncComponent";
import OtpRoute from "../components/app/OtpRoute";

const NotFoundRedirect = () => <Redirect to="/404" />;

const App = ({ match }) => {
  const [CanViewAllPages] = useClaims([ClaimKeys.CanViewAllPages]);
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}home`}
          component={asyncComponent(() => import("./home"))}
        />
        <Route
          path={`${match.url}tasks`}
          component={asyncComponent(() => import("./tasks"))}
        />
        <Route
          path={`${match.url}FeeStatus`}
          component={asyncComponent(() => import("./FeeStatus"))}
        />
        
        <Route
          path={`${match.url}admissionForm`}
          component={asyncComponent(() => import("./admissionForm"))}
        />
        {CanViewAllPages && (
          <>
            <Route
              path={`${match.url}profile`}
              component={asyncComponent(() => import("./Profile"))}
            />
            <Route
              path={`${match.url}sample`}
              component={asyncComponent(() => import("./SamplePage"))}
            />
            <Route
              path={`${match.url}ChangePassword`}
              component={asyncComponent(() =>
                import("../containers/ChangePassword")
              )}
            />
            <Route
              path={`${match.url}assessment`}
              component={asyncComponent(() => import("./assessment"))}
            />
            <Route
              path={`${match.url}syllabus`}
              component={asyncComponent(() => import("./syllabus"))}
            />
            <Route
              path={`${match.url}course`}
              component={asyncComponent(() => import("./eCourse"))}
            />
            <Route
              path={`${match.url}library`}
              component={asyncComponent(() => import("./eLibrary"))}
            />
            <Route
              path={`${match.url}otp`}
              component={asyncComponent(() => import("./otp"))}
            />

            {/* <OtpRoute
              path={`${match.url}FeeStatus`}
              // otpVerified={false} // {otpVerified}
              component={asyncComponent(() => import("./FeeStatus"))}
            /> */}

            {/* <Route
              path={`${match.url}comments`}
              component={asyncComponent(() => import("./comments"))}
            /> */}

            <Route
              path={`${match.url}diary`}
              component={asyncComponent(() => import("./DailyDiary"))}
            />
            <Route
              path={`${match.url}appraisals`}
              component={asyncComponent(() => import("./appraisals"))}
            />
            <Route
              path={`${match.url}appraisals/:appraisalId`}
              component={asyncComponent(() => import("./appraisals"))}
            />
            <Route
              path={`${match.url}events`}
              component={asyncComponent(() => import("./events"))}
            />
          </>
        )}

        <Route component={NotFoundRedirect} />
      </Switch>
    </div>
  );
};

export default App;
