import * as Actions from "../actions";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  campusListLoading: false,
  campusList: [],
  sessionListLoading: false,
  sessionList: [],
  latestClassListLoading: false,
  latestClassList: [],
  latestSubjectListLoading: false,
  latestSubjectList: [],
  designationListLoading: false,
  designationList: [],
  courseTypeListLoading: false,
  courseTypeList: [],
  courseCategoryListLoading: false,
  courseCategoryList: [],
  onlineClassListLoading: false,
  onlineClassList: [],
  classesForOnlineRegistrationListLoading: false,
  classesForOnlineRegistrationList: [],
  documentChecklistLoading: false,
  documentChecklist: [],

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.LOOKUP_CAMPUS_LOADING: {
      return {
        ...state,
        campusListLoading: true,
        campusList: [],
        sessionList: [],
        latestClassList: [],
        latestSubjectList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_SESSION_LOADING: {
      return {
        ...state,
        sessionListLoading: true,
        sessionList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_CLASSS_LOADING: {
      return {
        ...state,
        latestClassListLoading: true,
        latestClassList: [],
        latestSubjectList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_SUBJ_LOADING: {
      return {
        ...state,
        latestSubjectListLoading: true,
        latestSubjectList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_DESIGNATION_LOADING: {
      return {
        ...state,
        designationListLoading: true,
        designationList: [],
        error: null,
      };
    }

    case Actions.LOOKUP_CAMPUS_SUCCESS: {
      return {
        ...state,
        campusListLoading: false,
        campusList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_SESSION_SUCCESS: {
      return {
        ...state,
        sessionListLoading: false,
        sessionList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_CLASSS_SUCCESS: {
      return {
        ...state,
        latestClassListLoading: false,
        latestClassList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_SUBJ_SUCCESS: {
      return {
        ...state,
        latestSubjectListLoading: false,
        latestSubjectList: [...action.payload],
        error: null,
      };
    }
    case Actions.LOOKUP_DESIGNATION_SUCCESS: {
      return {
        ...state,
        designationListLoading: false,
        designationList: [...(action.payload || [])],
        error: null,
      };
    }

    case Actions.LOOKUP_CAMPUS_ERROR: {
      return {
        campusListLoading: false,
        campusList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_SESSION_ERROR: {
      return {
        sessionListLoading: false,
        sessionList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_CLASSS_ERROR: {
      return {
        latestClassListLoading: false,
        latestClassList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_SUBJ_ERROR: {
      return {
        latestSubjectListLoading: false,
        latestSubjectList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_DESIGNATION_ERROR: {
      return {
        designationListLoading: false,
        designationList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_COURSETYPE_LOADING: {
      return {
        ...state,
        courseTypeListLoading: true,
        courseTypeList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_COURSETYPE_SUCCESS: {
      return {
        ...state,
        courseTypeListLoading: false,
        courseTypeList: [...(action.payload || [])],
        error: null,
      };
    }
    case Actions.LOOKUP_COURSETYPE_ERROR: {
      return {
        courseTypeListLoading: false,
        courseTypeList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_COURSECATEGORY_LOADING: {
      return {
        ...state,
        courseCategoryListLoading: true,
        courseCategoryList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_COURSECATEGORY_SUCCESS: {
      return {
        ...state,
        courseCategoryListLoading: false,
        courseCategoryList: [...(action.payload || [])],
        error: null,
      };
    }
    case Actions.LOOKUP_COURSECATEGORY_ERROR: {
      return {
        courseCategoryListLoading: false,
        courseCategoryList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_CLASSESFORONLINEREGISTRATION_LOADING: {
      return {
        ...state,
        classesForOnlineRegistrationListloading: true,
        classesForOnlineRegistrationList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_CLASSESFORONLINEREGISTRATION_SUCCESS: {
      return {
        ...state,
        classesForOnlineRegistrationListloading: false,
        classesForOnlineRegistrationList: [...(action.payload || [])],
        error: null,
      };
    }
    case Actions.LOOKUP_CLASSESFORONLINEREGISTRATION_ERROR: {
      return {
        classesForOnlineRegistrationListloading: false,
        classesForOnlineRegistrationList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_ONLINE_CLASSES_LOADING: {
      return {
        ...state,
        onlineClassListLoading: true,
        onlineClassList: [],
        error: null,
      };
    }
    case Actions.LOOKUP_ONLINE_CLASSES_SUCCESS: {
      return {
        ...state,
        onlineClassListLoading: false,
        onlineClassList: [...(action.payload || [])],
        error: null,
      };
    }
    case Actions.LOOKUP_ONLINE_CLASSES_ERROR: {
      return {
        onlineClassListLoading: false,
        onlineClassList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LOOKUP_CHECKLIST_LOADING: {
      return {
        ...state,
        documentChecklistLoading: true,
        documentChecklist: [],
        error: null,
      };
    }
    case Actions.LOOKUP_CHECKLIST_SUCCESS: {
      return {
        ...state,
        documentChecklistLoading: false,
        documentChecklist: [...(action.payload || [])],
        error: null,
      };
    }
    case Actions.LOOKUP_CHECKLIST_ERROR: {
      return {
        documentChecklistLoading: false,
        documentChecklist: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
        latestClassListLoading: false,
      };
    }
  }
};
