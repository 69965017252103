import { axios } from "@mb";

class appraisalsService {
  getAppraisalList = async () =>
    new Promise((resolve, reject) => {
      try {
        axios
          .get(`api/appraisals`)
          .then(response => {
            if (response.data) {
              resolve(
                this.getGroupedAppraisals(response.data.data).sort(
                  (a, b) =>
                    new Date(b.dueDate).getTime() -
                    new Date(a.dueDate).getTime()
                )
              );
            } else {
              reject(response.data.meta);
            }
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });

  getGroupedAppraisals = data =>
    Object.values(
      data.reduce(
        (
          result,
          {
            grNo,
            taskId,
            name,
            taskTypeId,
            dueDate,
            status,
            campusId,
            session,
            sessionId,
            className,
            classId,
            id,
            appraisalTypeId,
            content
          }
        ) => {
          // Create new group
          if (!result[taskId])
            result[taskId] = {
              //id,
              grNo,
              taskId,
              name,
              taskTypeId,
              dueDate,
              status,
              campusId,
              session,
              sessionId,
              className,
              classId,
              appraisals: []
            };
          // Append to group
          result[taskId].appraisals.push({
            id,
            appraisalTypeId,
            content
          });
          return result;
        },
        {}
      )
    );
}

const instance = new appraisalsService();

export default instance;
