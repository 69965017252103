import * as Actions from "../actions";

const INIT_STATE = {
	isLoading: true,
	isVerifying: false,
	alertMessage: "",
	showMessage: false,
	otpReqSent: false,
	otpReqFailed: false,
	otpVerified: false,
	error: {
		code: null,
		message: null
	}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case Actions.OTP_LOADING: {
			return {
				...state,
				isLoading: true,
				otpReqSent: false,
				otpVerified: false,
				error: null
			};
		}
		case Actions.OTP_REQ_SENT: {
			return {
				...state,
				isLoading: false,
				otpReqSent: true,
				otpVerified: false,
				alertMessage: action.payload.message,
				showMessage: true,
				error: null
			};
		}
		case Actions.OTP_REQ_FAILED: {
			return {
				...state,
				isLoading: false,
				otpVerified: false,
				otpReqFailed: true,
				alertMessage: action.payload.message,
				showMessage: true,
				error: {
					...state.error,
					code: action.payload.code,
					message: action.payload.message
				}
			};
		}
		case Actions.OTP_VERIFYING: {
			return {
				...state,
				isVerifying: true,
				otpVerified: false,
				error: null
			};
		}
		case Actions.OTP_VERIFICATION_FAILED: {
			return {
				...state,
				isVerifying: false,
				otpVerified: false,
				alertMessage: action.payload.message,
				showMessage: true,
				error: null
			};
		}
		case Actions.OTP_VERIFIED: {
			return {
				...state,
				isVerifying: false,
				otpVerified: true,
				alertMessage: action.payload.message,
				showMessage: false,
				error: null
			};
		}
		case Actions.ADMIN_LOGIN_SUCCESS:
			{
				return {
					...state,
					isVerifying: false,
					otpVerified: true,
					showMessage: false,
					error: null
				};
			}
		case Actions.OTP_ERROR: {
			return {
				isLoading: false,
				otpVerified: false,
				otpReqFailed: true,
				error: {
					...state.error,
					code: action.payload.code,
					message: action.payload.message
				}
			};
		}
		case Actions.OTP_RESET_MESSAGE: {
			return {
				...state,
				alertMessage: "",
				showMessage: ""
			};
		}
		default: {
			return {
				...state,
				isLoading: false
			};
		}
	}
};
