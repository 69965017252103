import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./auth.reducers";
import Common from "./Common";
import Profile from "./profile.reducer";
import Dashboard from "./dashboard.reducers";
import FeeStatus from "./feeStatus.reducer";
import Assessment from "./assessment.reducers";
import Otp from "./otp.reducer";
import Comments from "./comments.reducers";
import Syllabus from "./syllabus.reducers";
import Tasks from "./tasks.reducers";
import Appraisals from "./appraisal.reducers";
import Diary from "./diary.reducers";
import Graphs from "./graphs.reducers";
import Library from "./library.reducers";
import Quiz from "./quiz.reducers";
import Media from "./media.reducers";
import Lookup from "./lookup.reducers";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    profile: Profile,
    dashboard: Dashboard,
    feeStatus: FeeStatus,
    assessment: Assessment,
    otp: Otp,
    comments: Comments,
    syllabus: Syllabus,
    tasks: Tasks,
    appraisals: Appraisals,
    diary: Diary,
    graphs: Graphs,
    library: Library,
    quiz: Quiz,
    media: Media,
    lookup: Lookup,
  });

export default reducers;
