import * as Actions from "../actions";
import * as common from "../actions/Common";

import theService from "appServices/diaryService";

export { fetchDailyDiary };

const fetchDailyDiary = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.DIARY_DETAILS_LIST_LOADING,
    Actions.DIARY_DETAILS_LIST_SUCCESS,
    Actions.DIARY_DETAILS_LIST_ERROR,
    theService.fetchDailyDiary,
    query
  );
