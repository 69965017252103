import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Select,
} from "antd";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { userSignUp } from "appRedux/actions/auth.actions";
import {
  fetchCourseTypeList,
  fetchCourseCategoryList,
  fetchOnlineClassList,
} from "appRedux/thunks";

import IntlMessages from "util/IntlMessages";
import { utils, dt, ui } from "@mb";
import "assets/styles/login.css";
import Registered from "./Registered";
import { SchoolType } from 'constants/GlobalConstants';

const FormItem = Form.Item;

const Register = (props) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [classes, setClasses] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [form] = Form.useForm();
  const { token, userRegInfo } = useSelector(({ auth }) => auth);
  const {
    courseTypeListLoading,
    courseTypeList,
    courseCategoryListLoading,
    courseCategoryList,
    onlineClassListLoading,
    onlineClassList,
  } = useSelector(({ lookup }) => lookup);

  useEffect(() => {
    if (!utils.isEmpty(token)) {
      props.history.push("/");
    }
    //  if (!courseTypeListLoading && utils.isEmpty(courseTypeList)) {
    //    dispatch(fetchCourseTypeList());
    //  }
    //  if (!courseCategoryListLoading && utils.isEmpty(courseCategoryList)) {
    //    dispatch(fetchCourseCategoryList());
    //  }
    if (!onlineClassListLoading && utils.isEmpty(onlineClassList)) {
      dispatch(fetchOnlineClassList());
    }
  }, []);

  useEffect(() => {
    if (utils.isEmpty(userRegInfo)) return;
    console.log(userRegInfo);
    message.info(
      "You will receive an email to process registration within next 24 working hours.",
      10
    );
    setShowSuccess(true);
  }, [userRegInfo]);

  if (showSuccess) {
    return <Registered info={userRegInfo} />;
  }

  let options = [],
    cats = [],
    catsClasses = [];
  if (!utils.isEmpty(courseTypeList)) {
    options = ui.getDrowpdownOptions(
      courseTypeList,
      {},
      "numericValue",
      "displayText"
    );
  }
  if (!utils.isEmpty(categories)) {
    cats = ui.getDrowpdownOptions(
      categories,
      {},
      "numericValue",
      "displayText"
    );
  }
  if (!utils.isEmpty(onlineClassList)) {
    catsClasses = ui.getDrowpdownOptions(onlineClassList, {}, "value", "displayText");
  }

   const onChangeCourseType = (value, option) => {
     form.setFieldsValue({ category: null });


     //console.log(value, option);
     const newCatList = courseCategoryList.filter(
       (l) => l.textValue == option.children
    );
     //console.log(newCatList);
     setCategories(newCatList);
   };

 // setCategories(courseTypeList.filter(courseTypeList=>courseTypeList.textValue=="Academic"))
  const onChangeCourseCats = (value, option) => {

    form.setFieldsValue({ classes: null });
 

    //console.log(value, option);
    const newCatsClassList = onlineClassList.filter(
      (l) => l.data == option.value
    );
    //console.log(newCatsClassList);
    setClasses(newCatsClassList);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.warn("Please provide with required information!");
  };

  const onFinish = (values) => {
    const studentToRegister = {
      ...values,
      dob: dt.toISOString(values.dob),
      schoolId: SchoolType.Online,
    };
    console.log(studentToRegister);
    dispatch(userSignUp(studentToRegister));

  
  };

  return (
    <div className="login-background">
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/* <img src="https://via.placeholder.com/272x395" alt="Neature" /> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.register" />
                </h1>
                <p>REGISTRATION FORM</p>
                <p></p>
              </div>
              <div className="gx-app-logo">
                <img
                  alt="The Online Schooling"
                  src={require("assets/images/logo.png")}
                />
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form
                form={form}
                initialValues={{ 
                remember: true
                }}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem
                  rules={[
                    { required: true, message: "Student Name is required!" },
                  ]}
                  name="Name"
                >
                  <Input placeholder="Student Name" autoFocus maxLength="50" />
                </FormItem>
                <FormItem
                  rules={[
                    { required: true, message: "Father Name is required!" },
                  ]}
                  name="FatherName"
                >
                  <Input placeholder="Father Name" maxLength="50" />
                </FormItem>

                <Form.Item
                  rules={[{ required: true, message: "Please select one!" }]}
                  name="Gender"
                >
                  <Radio.Group>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  rules={[
                    { required: true, message: "Date of Birth is required!" },
                  ]}
                  name="dob"
                >
                  <DatePicker
                    // defaultValue={moment().subtract(3, "years")}
                    format={dt.dateFormatList}
                    placeholder="Select Date of Birth"
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current > dt.yesterday()
                    }
                    inputReadOnly
                  />
                </Form.Item>

                <FormItem
                  name="email"
                  extra="Please enter active email address. All official communication will be through email only."
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Valid E-mail is required!",
                    },
                  ]}
                >
                  <Input placeholder="Email" maxLength="50" />
                </FormItem>
                <FormItem
                  name="altEmail"
                  rules={[
                    {
                     // required: true,
                      type: "email",
                      message: "Valid E-mail is required!",
                    },
                  ]}
                >
                  <Input placeholder="Alternate Email" maxLength="50" />
                </FormItem>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Phone number is required!",
                    },
                  ]}
                >
                  <Input placeholder="Phone Number" maxLength="20" />
                </Form.Item>
                {/* <Form.Item
                  name="level"
                  rules={[
                    {
                      required: true,
                      message: "Level is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Level"
                    onChange={onChangeCourseType}
                  >
                    {options}
                  </Select>
                </Form.Item> */}
                {/* <Form.Item
                  name="courseCategory"
                  rules={[
                    {
                      required: true,
                      message: "Category is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Category"
                    onChange={onChangeCourseCats}
                     >
                    {cats}
                  </Select>
                </Form.Item> */}
                <Form.Item
                  name="courseClass"
                  rules={[
                    {
                      required: true,
                      message: "Course Class is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Class"
                    onChange={onChangeCourseType}
                  >
                    {catsClasses}
                    
                  </Select>
                </Form.Item>
                <Form.Item name="content">
                  <Input.TextArea
                    placeholder="Previous Class and Achievements (Optional)"
                    maxLength="100"
                  />
                </Form.Item>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.register" />
                  </Button>
                  <span>
                    <IntlMessages id="app.userAuth.or" />
                  </span>{" "}
                  <Link to="/signin">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Link>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
