import * as Actions from "../actions/";
import * as common from "../actions/Common";

import theService from "appServices/profileService";

export {
  loadProfile,
  getMySessionList,
  loadAttendanceForGraph,
  loadResultForGraph,
  saveProfile,
};

const loadProfile = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.PROFILE_LOADING,
    Actions.PROFILE_SUCCESS,
    Actions.PROFILE_ERROR,
    theService.getMyProfile
  );

  
const saveProfile = (query) => async (dispatch) => {
  try {
    console.log("befThunk",query);
    dispatch(common.fetchStart(Actions.PROFILE_SAVING));
    
    const result = await theService.saveProfile(query);
    return dispatch(common.fetchSuccessWithData(Actions.PROFILE_SAVED, result),
    console.log("thunk",result)
    );
  } catch (error) {
    return dispatch(
      common.fetchError(error, Actions.PROFILE_SAVE_ERROR)
    );
  }
}
const getMySessionList = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.PROFILE_SESSION_LIST_LOADING,
    Actions.PROFILE_SESSION_LIST_SUCCESS,
    Actions.PROFILE_SESSION_LIST_ERROR,
    theService.getMySessionList
  );

const loadAttendanceForGraph = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.GRAPH_ATTENDANCE_LOADING,
    Actions.GRAPH_ATTENDANCE_SUCCESS,
    Actions.GRAPH_ATTENDANCE_ERROR,
    theService.getAttendanceForGraph
  );

const loadResultForGraph = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.GRAPH_RESULT_LOADING,
    Actions.GRAPH_RESULT_SUCCESS,
    Actions.GRAPH_RESULT_ERROR,
    theService.getResultForGraph
  );
