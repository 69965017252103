import * as Actions from "../actions/diary.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  pageInfo: PageInfo,
  diaryItemId: null,
  diaryItem: null,
  diaryItemLoading: false,
  diaryItemDetailList: [],
  diaryItemDetailListLoading: false,
  diaryItemDetailSaving: false,

  diaryListLoading: false,
  diaryList: [],
  diaryMediaUploading: false,
  diaryMediaInfo: null,

  error: {
    code: null,
    message: null,
  },
};

const getGroupedDiary = (data) =>
  Object.values(
    data.reduce(
      (
        result,
        {
          subjectId,
          subjectName,
          diaryTypeId,
          diaryTypeName,
          postedFor,
          diaryDetailId,
          diaryId,
          contentTypeId,
          diaryContentType,
          content,
        }
      ) => {
        // Create new group
        if (!result[diaryId])
          result[diaryId] = {
            diaryId,
            subjectId,
            subjectName,
            postedFor,
            diary: [],
          };
        // Append to group
        result[diaryId].diary.push({
          diaryDetailId,
          diaryTypeId,
          diaryTypeName,
          contentTypeId,
          diaryContentType,
          content,
        });
        return result;
      },
      {}
    )
  );

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.DIARY_DETAILS_LIST_LOADING: {
      return {
        ...state,
        diaryItemDetailListLoading: true,
        diaryItemDetailList: null,
        error: null,
      };
    }
    case Actions.DIARY_DETAILS_LIST_SUCCESS: {
      const dd = [...action.payload];
      return {
        ...state,
        diaryItemDetailListLoading: false,
        diaryItemDetailList: [...getGroupedDiary(dd)],
        error: null,
      };
    }
    case Actions.DIARY_DETAILS_LIST_ERROR: {
      return {
        ...state,
        diaryItemDetailListLoading: false,
        diaryItemDetailList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    default: {
      return {
        ...state,
        isLoading: false,
        diaryListLoading: false,
      };
    }
  }
};
