import { api } from "@mb";

const tasksService = {
  submitFetchTaskData: async (key) => api.get(`api/tasks/${key}`),

  submitTaskContent: async (taskInfo) => api.post(`api/tasks`, taskInfo),

  getTaskList: async (sessionId) =>
    api.get(`api/tasks/ForStudent/${sessionId}`),

  getQuizGenerationInfo: async (key) =>
    api.get(`api/tasks/QuizGenerationInfo/${key}`),

  submitQuizForAdmission: async (params) =>
    api.post(`/api/tasks/submitQuiz`, params),

  fetchAssessmentResult: async (params) =>
    api.get(`api/tasks/assessment/${params}`),
};

export default tasksService;
