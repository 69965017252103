import * as Actions from "../actions";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  taskInfo: null,
  taskListLoading: false,
  taskList: [],
  quizInfoLoading: false,
  quizInfo: null,
  taskSubmitting: false,
  taskSubmitted: false,
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.TASKS_LOADING: {
      return {
        ...state,
        isLoading: true,
        taskSubmitted: false,
        error: null,
      };
    }
    case Actions.ASSESSMENT_RESULT_LOADING: {
      return {
        ...state,
        quizInfoLoading: true,
        taskSubmitted: false,
        error: null,
      };
    }
    case Actions.ASSESSMENT_RESULT_SUCCESS: {
      if (action.payload.submitted === false) {
        // This is explicitly checking for false because if we successfully find something, we have no submitted field
        return {
          ...state,
          quizInfoLoading: false,
          taskSubmitted: false,
          quizInfo: action.payload,
          error: null,
        };
      }

      return {
        ...state,
        quizInfoLoading: false,
        taskSubmitted: true,
        quizInfo: action.payload,
        error: null,
      };
    }
    case Actions.TASKS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        taskInfo: action.payload,
        error: null,
      };
    }
    case Actions.TASKS_ERROR: {
      return {
        isLoading: false,
        taskInfo: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.TASKS_LIST_LOADING: {
      return {
        ...state,
        taskListLoading: true,
        error: null,
      };
    }
    case Actions.TASKS_LIST_SUCCESS: {
      return {
        ...state,
        taskListLoading: false,
        taskList: [...action.payload],
        error: null,
      };
    }
    case Actions.QUIZ_ERROR: {
      return {
        quizInfoLoading: false,
        quizInfo: null,
        taskSubmitted: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUIZ_LOADING: {
      return {
        ...state,
        quizInfoLoading: true,
        taskSubmitted: false,
        quizInfo: null,
        taskList: [],
        error: null,
      };
    }
    case Actions.TASK_SUBMITTING: {
      return {
        ...state,
        taskSubmitting: true,
        taskSubmitted: false,
        error: null,
      };
    }
    case Actions.TASK_SUBMIT_SUCCESS: {
      return {
        ...state,
        taskSubmitting: false,
        taskSubmitted: true,
        quizInfo: action.payload,

        error: null,
      };
    }
    case Actions.QUIZ_SUCCESS: {
      return {
        ...state,
        quizInfoLoading: false,
        quizInfo: action.payload,
        error: null,
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
      };
    }
  }
};
