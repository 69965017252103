import { axios } from "@mb";

class otpService  {
  sendOtp = async () => {
    return new Promise((resolve, reject) => {

      try {
        axios
          .post(`api/auth/otp/send`)
          .then(response => {
            if (response.data) {
              resolve(response.data.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  verifyOtp = async (otp) => {
    return new Promise((resolve, reject) => {

      try {
        axios
          .post(`api/auth/otp/verify`, {
            otp: otp.otp
          })
          .then(response => {
            if (response.data) {
              resolve(response.data.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

}

const instance = new otpService();

export default instance;
