import { useSelector } from "react-redux";
import { useClaims, ClaimKeys } from "@mb";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
/*import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";*/
import { menuList } from "./data.menu.content";
import { EllipsisOutlined } from "@ant-design/icons";

const HorizontalNav = () => {
  const claimKeys = Object.keys(ClaimKeys);
  const claims = useClaims(claimKeys);

  //const navStyle = useSelector(({ settings }) => settings.navStyle);
  const { pathname } = useSelector(({ common }) => common);

  /*const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };*/

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      overflowedIndicator={<EllipsisOutlined className="gx-text-white" />}
    >
      {menuList.map(
        (data, _) =>
          claims[claimKeys.indexOf(data.claimKey)] && (
            <Menu.Item key={data.key}>
              <Link
                to={data.linkTo}
                className={`${
                  data.key == selectedKeys
                    ? "gx-text-secondary"
                    : "gx-text-light-grey"
                } gx-text-uppercase`}
              >
                <IntlMessages id={data.intlMessagesId} /> &nbsp;
              </Link>
            </Menu.Item>
          )
      )}
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;