import * as Actions from "../actions";

const INIT_STATE = {
  subjectListLoading: true,
  syllabusDetailsLoading: true,
  alertMessage: "",
  showMessage: false,
  subjectList: [],
  syllabusDetails: [],
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.SUBJECT_LIST_LOADING: {
      return {
        ...state,
        subjectListLoading: true,
        subjectList: [],
        error: null,
      };
    }
    case Actions.SUBJECT_LIST_SUCCESS: {
      return {
        ...state,
        subjectListLoading: false,
        subjectList: action.payload,

        error: null,
      };
    }
    case Actions.SUBJECT_LIST_ERROR: {
      return {
        ...state,
        subjectListLoading: false,
        subjectList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.SYLLABUS_DETAILS_LOADING: {
      return {
        ...state,
        syllabusDetailsLoading: true,
        //syllabusDetails: [],
        error: null,
      };
    }
    case Actions.SYLLABUS_DETAILS_SUCCESS: {
      let sd = state.syllabusDetails;
      if (
        !state.syllabusDetails.some(
          (sd) => sd.subjectId === action.payload.subjectId
        )
      ) {
        sd.push(action.payload);
      }
      return {
        ...state,
        syllabusDetailsLoading: false,
        syllabusDetails: [...sd],

        error: null,
      };
    }
    case Actions.SYLLABUS_DETAILS_ERROR: {
      return {
        ...state,
        syllabusDetailsLoading: false,
        //syllabusDetails: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        subjectListLoading: false,
        syllabusDetailsLoading: false,
      };
    }
  }
};
