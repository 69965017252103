import * as Actions from "../actions";

const INIT_STATE = {
  examListLoading: false,
  examDetailsLoading: false,
  examReportLoading: false,
  alertMessage: "",
  showMessage: false,
  examList: [],
  examDetails: [],
  examReportPdf: null,
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.EXAM_LIST_LOADING: {
      return {
        ...state,
        examListLoading: true,
        examList: [],
        error: null,
      };
    }
    case Actions.EXAM_LIST_SUCCESS: {
      return {
        ...state,
        examListLoading: false,
        examList: action.payload,

        error: null,
      };
    }
    case Actions.EXAM_LIST_ERROR: {
      return {
        ...state,
        examListLoading: false,
        examList: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EXAM_DETAILS_LOADING: {
      return {
        ...state,
        examDetailsLoading: true,
        examDetails: [],
        error: null,
      };
    }
    case Actions.EXAM_DETAILS_SUCCESS: {
      return {
        ...state,
        examDetailsLoading: false,
        examDetails: action.payload,

        error: null,
      };
    }
    case Actions.EXAM_DETAILS_ERROR: {
      return {
        ...state,
        examDetailsLoading: false,
        examDetails: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.EXAM_REPORT_LOADING: {
      return {
        ...state,
        examReportLoading: true,
        examReportPdf: null,
        error: null,
      };
    }
    case Actions.EXAM_REPORT_SUCCESS: {
      return {
        ...state,
        examReportLoading: false,
        examReportPdf: action.payload,
        error: null,
      };
    }
    case Actions.EXAM_REPORT_DOWNLOADED: {
      return {
        ...state,
        examReportLoading: false,
        examReportPdf: null,
        error: null,
      };
    }
    case Actions.EXAM_REPORT_ERROR: {
      return {
        ...state,
        examReportLoading: false,
        examReportPdf: [],
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        examListLoading: false,
        examDetailsLoading: false,
      };
    }
  }
};
