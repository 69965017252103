import * as Actions from "../actions";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,

  quizViewLoading: false,
  quizInfoForView: null,

  quizQuestionListLoading: false,
  quizQuestionList: [],

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.QUIZ_QUESTION_LIST_LOADING: {
      return {
        ...state,
        quizQuestionListLoading: true,
        quizQuestionList: [],
        error: null,
      };
    }
    case Actions.QUIZ_QUESTION_LIST_SUCCESS: {
      return {
        ...state,
        quizQuestionListLoading: false,
        quizQuestionList: [...action.payload],

        error: null,
      };
    }
    case Actions.QUIZ_QUESTION_LIST_ERROR: {
      return {
        ...state,
        quizQuestionListLoading: false,
        quizQuestionList: [],

        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.QUIZ_QUESTION_VIEW_LOADING: {
      return {
        ...state,
        quizViewLoading: true,
        error: null,
      };
    }
    case Actions.QUIZ_QUESTION_VIEW_SUCCESS: {
      return {
        ...state,
        quizViewLoading: false,
        quizInfoForView: action.payload.data,
        error: null,
      };
    }
    case Actions.QUIZ_QUESTION_VIEW_ERROR: {
      return {
        ...state,
        quizViewLoading: false,
        quizInfoForView: null,

        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
        isLoading: false,
        quizQuestionListLoading: false,
        quizViewLoading: false,
      };
    }
  }
};
