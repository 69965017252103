import { axios } from "../../@mb";

class commentService  {
  addComments = async (comments) => {
    return new Promise((resolve, reject) => {

      try {
        axios
          .post(`api/comments`,{comments})
          .then(response => {
            if (response.data) {
              resolve(response.data.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

}

const instance = new commentService();

export default instance;
