import { api } from "@mb";

const profileService = {
  getMyProfile: async () => api.get(`api/profile/me`),
  saveProfile: async (profile) => api.post(`api/profile`,profile),

  getMySessionList: async () => api.get(`api/profile/grades/all`),
  getAttendanceForGraph: async () => api.get(`api/profile/progress/attendance`),

  getResultForGraph: async () => api.get(`api/profile/progress/result`),
};

export default profileService;
