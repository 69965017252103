import { axios, utils } from "../../@mb";

class feeService {
  getfeeStatus = async (sessionId) => {
    return new Promise((resolve, reject) => {
      const sessionInfo = utils.isEmpty(sessionId)
        ? ""
        : `?sessionId=${sessionId}`;
      try {
        axios
          .get(`api/fee/status${sessionInfo}`)
          .then((response) => {
            if (response.data) {
              resolve(response.data.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  downloadFeeVoucher = async (feeInfo) => {
    console.log(process.env.REACT_APP_REPORT_SERVER_DOMAIN);
    return new Promise((resolve, reject) => {
      const { challanId, campusShortCode } = feeInfo;
      //console.log(challanId, campusShortCode);
      
      try {
        axios
          .post(
            `api/fee/pdf`,
            {
              challanId,
              campusShortCode,
              urlHost: process.env.REACT_APP_REPORT_SERVER_DOMAIN,
            },
            {
              responseType: "blob", //Force to receive data in a Blob Format
            }
          )
          .then((response) => {
            if (response.data) {
              resolve(response.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
}

const instance = new feeService();

export default instance;
