import { api, utils } from "@mb";

const dashboardService = {
  getSubmittedDocumentsForAdmission: async () =>
    api.get(`api/AdmissionCheckList`),
  submitDocumentsForAdmission: async (query) =>
    api.post(`api/AdmissionCheckList`, query),

  getBasicProfile: async () => api.get(`api/profile/basic`),

  getEventList: async (fromDate, toDate) =>
    api.get(
      `api/events${`?fromDate=${fromDate || ""}&toDate=${toDate || ""}`}`
    ),

  getNotifications: async (filter) =>
    api.post(`api/notification/fetch`, filter),
  getTimetable: async (filter) =>
    api.post(`api/notification/timetable`, filter),

  getIntroInfo: (grNo) => {
    //debugger;
    const watchedInfo = localStorage[`watchInfo${grNo}`];
    if (!utils.isEmpty(watchedInfo)) {
      const data = JSON.parse(watchedInfo);
      if (!utils.isEmpty(data)) {
        if (data.grNo === grNo) return null;
      }
    }

    return {
      // videoUrl: "https://edpuzzle.com/embed/media/5ddacf13f9018940a4355d12"
      videoUrl: "https://vizia.co/videos/eb1e36a11c8dd9b2ec24b7/embed",
    };
  },

  setIntroIsWatchedInfo: (grNo) => {
    // debugger;
    if (grNo) {
      const watchedInfo = {
        grNo,
        watchedOn: new Date(),
      };
      localStorage.setItem(`watchInfo${grNo}`, JSON.stringify(watchedInfo));
      return watchedInfo;
    }
    return null;
  },

  submitIntroWatched: async (grNo) =>
    new Promise((resolve, reject) => {
      try {
        const watchedInfo = this.setIntroIsWatchedInfo(grNo);
        watchedInfo
          ? resolve(watchedInfo)
          : reject({ code: 0, message: "No GR Provided." });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    }),
  getIntroUrl: async (grNo) =>
    new Promise((resolve, reject) => {
      try {
        const data = this.getIntroInfo(grNo);
        data ? resolve(data) : reject({ code: 0, message: "Already watched." });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    }),
  getElectronicContentRecommendations: async (query) =>
    api.getPagedList("api/ElectronicContentRecommendation/paged", query),
};

export default dashboardService;
