import {api, utils } from "@mb";

const SyllabusService = {
  getSubjectList: async (gradeId) =>
    api.get(
      `api/grade/subjects${utils.isEmpty(gradeId) ? "" : `?gradeId=${gradeId}`}`
    ),
  getSyllabusDetails: async (subjectId) =>
    api.get(
      `api/syllabus/subject${
        utils.isEmpty(subjectId) ? "" : `?subjectId=${subjectId}`
      }`
    ),
};

export default SyllabusService;
