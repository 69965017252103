export const EXAM_LIST_ERROR = "EXAM_LIST_ERROR";
export const EXAM_LIST_SUCCESS = "EXAM_LIST_SUCCESS";
export const EXAM_LIST_LOADING = "EXAM_LIST_LOADING";
export const EXAM_DETAILS_ERROR = "EXAM_DETAILS_ERROR";
export const EXAM_DETAILS_SUCCESS = "EXAM_DETAILS_SUCCESS";
export const EXAM_DETAILS_LOADING = "EXAM_DETAILS_LOADING";
export const EXAM_REPORT_ERROR = "EXAM_REPORT_ERROR";
export const EXAM_REPORT_LOADING = "EXAM_REPORT_LOADING";
export const EXAM_REPORT_SUCCESS = "EXAM_REPORT_SUCCESS";
export const EXAM_REPORT_DOWNLOADED = "EXAM_REPORT_DOWNLOADED";
