import * as Actions from "../actions";

const INIT_STATE = {
  attendanceList: [],
  attendanceListLoading: false,
  shouldFetchAttendance: true,
  resultList: [],
  resultListLoading: false,
  shouldFetchResult: true,

  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.GRAPH_ATTENDANCE_LOADING: {
      return {
        ...state,
        attendanceListLoading: true,
        shouldFetchAttendance: false,
        error: null,
      };
    }

    case Actions.GRAPH_ATTENDANCE_SUCCESS: {
      const data = [...action.payload];
      const result = data.map((d) => {
        d.percentPresent = Math.ceil((d.presentDays * 100.0) / d.totalDays);
        d.xLabel = `${d.termName} ${d.sectionName}`;
        return d;
      });
      return {
        ...state,
        attendanceListLoading: false,
        attendanceList: [...result],
        error: null,
      };
    }

    case Actions.GRAPH_ATTENDANCE_ERROR: {
      return {
        ...state,
        attendanceListLoading: false,
        shouldFetchAttendance: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.GRAPH_RESULT_LOADING: {
      return {
        ...state,
        resultListLoading: true,
        shouldFetchResult: false,
        error: null,
      };
    }

    case Actions.GRAPH_RESULT_SUCCESS: {
      return {
        ...state,
        resultListLoading: false,
        resultList: [...action.payload],
        error: null,
      };
    }

    case Actions.GRAPH_RESULT_ERROR: {
      return {
        ...state,
        resultListLoading: false,
        shouldFetchResult: false,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
