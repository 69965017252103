import * as Actions from "../actions";
import * as common from "../actions/Common";

import tasksService from "appServices/tasksService";

export {
  submitFetchTaskData,
  submitTaskContent,
  getTaskList,
  fetchQuiz,
  submitQuizForAdmission,
  fetchAssessmentResult
};

const fetchAssessmentResult = (query) => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.ASSESSMENT_RESULT_LOADING,
    Actions.ASSESSMENT_RESULT_SUCCESS,
    Actions.TASKS_ERROR,
    tasksService.fetchAssessmentResult,
    query
  );
};

const submitQuizForAdmission = (query) => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.TASK_SUBMITTING,
    Actions.TASK_SUBMIT_SUCCESS,
    Actions.TASKS_ERROR,
    tasksService.submitQuizForAdmission,
    query
  );
};

function submitFetchTaskData(key) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.TASKS_LOADING));

      const result = await tasksService.submitFetchTaskData(key);
      return dispatch(
        common.fetchSuccessWithData(Actions.TASKS_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.TASKS_ERROR));
    }
  };
}

function submitTaskContent(taskInfo) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.TASK_SUBMITTING));

      const result = await tasksService.submitTaskContent(taskInfo);
      return dispatch(
        common.fetchSuccessWithData(Actions.TASK_SUBMIT_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.TASKS_ERROR));
    }
  };
}

function getTaskList(sessionId) {
  return async (dispatch) => {
    dispatch(common.fetchStart(Actions.TASKS_LIST_LOADING));
    tasksService
      .getTaskList(sessionId)
      .then((taskList) => {
        return dispatch(
          common.fetchSuccessWithData(Actions.TASKS_LIST_SUCCESS, taskList)
        );
      })
      .catch((error) => {
        debugger;
        console.error(`Error****:`, error);
        dispatch(common.fetchError(error));
      });
  };
}

function fetchQuiz(key) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.QUIZ_LOADING));

      const result = await tasksService.getQuizGenerationInfo(key);
      return dispatch(
        common.fetchSuccessWithData(Actions.QUIZ_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.QUIZ_ERROR));
    }
  };
}
