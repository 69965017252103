import { axios, utils } from "@mb";

class assessmentService {
  getExamList = async (sessionId) => {
    return new Promise((resolve, reject) => {
      const sessionInfo = utils.isEmpty(sessionId)
        ? ""
        : `?sessionId=${sessionId}`;
      try {
        axios
          .get(`api/exam/list${sessionInfo}`)
          .then((response) => {
            if (response.data) {
              resolve(response.data.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  getExamDetails = async (examId) => {
    return new Promise((resolve, reject) => {
      var res = examId.toString().replace(/[,]/g, "&examId=");

      const examInfo = utils.isEmpty(res) ? "" : `?examId=${res}`;
      try {
        axios
          .get(`api/exam/details${examInfo}`)
          .then((response) => {
            if (response.data) {
              resolve(response.data.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  getExamPdf = async (examId) => {
    return new Promise((resolve, reject) => {
      const examInfo = utils.isEmpty(examId) ? "" : `?examId=${examId}`;
      debugger;
      try {
        // ax(`api/exam/pdf${examInfo}`, {
        //   method: "GET",
        //   responseType: "blob" //Force to receive data in a Blob Format
        // })
        //.get(`api/exam/pdf${examInfo}`)
        axios
          .get(
            `api/exam/pdf${examInfo}`,
            {
              responseType: "blob", //Force to receive data in a Blob Format
            }
          )
          .then((response) => {
            if (response.data) {
              //console.log(response.data);
              resolve(response.data);
            } else {
              reject(response.data.meta);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
}

const instance = new assessmentService();

export default instance;
