import * as Actions from "../actions";
import * as common from "../actions/Common";

import theService from "appServices/quizService";

export { fetchQuizQuestionList, setQuizInfoForView };

const fetchQuizQuestionList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.QUIZ_QUESTION_LIST_LOADING,
    Actions.QUIZ_QUESTION_LIST_SUCCESS,
    Actions.QUIZ_QUESTION_LIST_ERROR,
    theService.getQuestionList,
    query
  );

const setQuizInfoForView = (quizInfo) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.QUIZ_QUESTION_VIEW_LOADING,
    Actions.QUIZ_QUESTION_VIEW_SUCCESS,
    Actions.QUIZ_QUESTION_VIEW_ERROR,
    async (quizInfo) => quizInfo,
    quizInfo
  );