import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";
import { utils } from "@mb";

export const fetchStart = (startType = FETCH_START) => ({
  type: startType,
});

export const fetchSuccess = (successType = FETCH_SUCCESS) => ({
  type: successType,
});

export const fetchSuccessWithData = (successType = FETCH_SUCCESS, payload) => ({
  type: successType,
  payload,
});

export const fetchError = (error, errorType = FETCH_ERROR) => ({
  type: errorType,
  payload: error,
});

export const showMessage = (message) => ({
  type: SHOW_MESSAGE,
  payload: message,
});

// export const hideMessage = () => ({
//   type: HIDE_MESSAGE,
// });

export const thunkHelper = async (
  dispatch,
  loading,
  success,
  error,
  delegate,
  data,
  additionalInfo = {}
) => {
  try {
    dispatch(fetchStart(loading));

    const result = await delegate(data);
    if (!utils.isEmpty(additionalInfo)) result.additionalInfo = additionalInfo;
    return dispatch(fetchSuccessWithData(success, result));
  } catch (ex) {
    debugger;
    return dispatch(fetchError(ex, error));
  }
};
