export const menuList = [
  {
    key: "home",
    linkTo: "/home",
    iconClass: "icon icon-home",
    intlMessagesId: "sidebar.homePage"
  },
  {
    key: "profile",
    linkTo: "/profile",
    iconClass: "icon icon-user",
    intlMessagesId: "sidebar.profilePage"
  },
  {
    key: "assessment",
    linkTo: "/assessment",
    iconClass: "icon icon-tasks",
    intlMessagesId: "sidebar.assessmentPage"
  },
  {
    key: "FeeStatus",
    linkTo: "/FeeStatus",
    iconClass: "icon icon-pricing-table",
    intlMessagesId: "sidebar.feeStatusPage"
  },
  {
    key: "syllabus",
    linkTo: "/syllabus",
    iconClass: "icon icon-product-list",
    intlMessagesId: "sidebar.syllabusPage"
  },
  {
    key: "course",
    linkTo: "/course",
    iconClass: "icon icon-visits",
    intlMessagesId: "sidebar.eCoursePage"
  },
  {
    key: "tasks",
    linkTo: "/tasks",
    iconClass: "icon icon-feedback",
    intlMessagesId: "sidebar.tasksPage"
  },
  {
    key: "diary",
    linkTo: "/diary",
    iconClass: "icon icon-feedback",
    intlMessagesId: "sidebar.diaryPage"
  },
  // {
  //   key: "appraisals",
  //   linkTo: "/appraisals",
  //   iconClass: "icon icon-feedback",
  //   intlMessagesId: "sidebar.appraisalsPage"
  // },
  {
    key: "library",
    linkTo: "/library",
    iconClass: "icon icon-orders",
    intlMessagesId: "sidebar.eLibraryPage"
  }
  // {
  //   key: "videos",
  //   linkTo: "/videos",
  //   iconClass: "icon icon-data-display",
  //   intlMessagesId: "sidebar.videosPage"
  // }
];
