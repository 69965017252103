export const DIARY_LIST_LOADING = "DIARY_LIST_LOADING";
export const DIARY_LIST_SUCCESS = "DIARY_LIST_SUCCESS";
export const DIARY_LIST_ERROR = "DIARY_LIST_ERROR";

export const DIARY_ITEM_LOADING = "DIARY_ITEM_LOADING";
export const DIARY_ITEM_SUCCESS = "DIARY_ITEM_SUCCESS";
export const DIARY_ITEM_ERROR = "DIARY_ITEM_ERROR";

export const DIARY_DETAILS_LIST_LOADING = "DIARY_DETAILS_LIST_LOADING";
export const DIARY_DETAILS_LIST_SUCCESS = "DIARY_DETAILS_LIST_SUCCESS";
export const DIARY_DETAILS_LIST_ERROR = "DIARY_DETAILS_LIST_ERROR";

export const DIARY_DETAIL_ITEM_SAVING = "DIARY_DETAIL_ITEM_SAVING";
export const DIARY_DETAIL_ITEM_SAVE_SUCCESS = "DIARY_DETAIL_ITEM_SAVE_SUCCESS";
export const DIARY_DETAIL_ITEM_SAVE_ERROR = "DIARY_DETAIL_ITEM_SAVE_ERROR";
