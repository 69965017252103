import * as Actions from "../actions";
import * as common from "../actions/Common";

import theService from "appServices/libraryService";
import dashboardSvc from "appServices/dashboardService";

export { getLibraryList, getlibraryItemWithPlayList, getRecommendations };

const getLibraryList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LIBRARY_LIST_LOADING,
    Actions.LIBRARY_LIST_SUCCESS,
    Actions.LIBRARY_LIST_ERROR,
    theService.getLibraryList,
    query
  );

const getlibraryItemWithPlayList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LIBRARY_PLAYLIST_LOADING,
    Actions.LIBRARY_PLAYLIST_SUCCESS,
    Actions.LIBRARY_PLAYLIST_ERROR,
    theService.getlibraryItemWithPlayList,
    query
  );

const getRecommendations = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.LIBRARY_LIST_LOADING,
    Actions.LIBRARY_LIST_SUCCESS,
    Actions.LIBRARY_LIST_ERROR,
    dashboardSvc.getElectronicContentRecommendations,
    query
  );
