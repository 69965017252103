export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_IMAGE_SUCCESS = "PROFILE_IMAGE_SUCCESS";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_SESSION_LIST_LOADING = "PROFILE_SESSION_LIST_LOADING";
export const PROFILE_SESSION_LIST_SUCCESS = "PROFILE_SESSION_LIST_SUCCESS";
export const PROFILE_SESSION_LIST_ERROR = "PROFILE_SESSION_LIST_ERROR";

export const PROFILE_SAVING = "PROFILE_SAVING";
export const PROFILE_SAVED = "PROFILE_SAVED";
export const PROFILE_SAVED_HANDLED = "PROFILE_SAVED_HANDLED";
export const PROFILE_SAVE_ERROR = "PROFILE_SAVE_ERROR";


export const profileActionCreators = {
  profileIsLoading: isLoading => ({
    type: PROFILE_LOADING,
    isLoading: isLoading
  })
};
