export const TASKS_ERROR = "TASKS_ERROR";
export const TASKS_SUCCESS = "TASKS_SUCCESS";
export const TASKS_LOADING = "TASKS_LOADING";
export const TASKS_LIST_ERROR = "TASKS_LIST_ERROR";
export const TASKS_LIST_SUCCESS = "TASKS_LIST_SUCCESS";
export const TASKS_LIST_LOADING = "TASKS_LIST_LOADING";
export const QUIZ_ERROR = "QUIZ_ERROR";
export const QUIZ_SUCCESS = "QUIZ_SUCCESS";
export const QUIZ_LOADING = "QUIZ_LOADING";
export const TASK_SUBMITTING = "TASK_SUBMITTING";
export const TASK_SUBMIT_SUCCESS = "TASK_SUBMIT_SUCCESS";
export const ASSESSMENT_RESULT_LOADING = "ASSESSMENT_RESULT_LOADING";
export const ASSESSMENT_RESULT_SUCCESS = "ASSESSMENT_RESULT_SUCCESS";
