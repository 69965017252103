import * as Actions from "../actions/library.actions";
import { PageInfo } from "../../constants/GlobalConstants";
import { utils } from "../../@mb";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  pageInfo: PageInfo,
  libraryListLoading: false,
  libraryList: [],
  libraryItemWithPlayListLoading: false,
  libraryItemWithPlayList: null,
  error: {
    code: null,
    message: null,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.LIBRARY_LIST_LOADING: {
      return {
        ...state,
        libraryListLoading: true,
        libraryList: null,
        error: null,
      };
    }
    case Actions.LIBRARY_LIST_SUCCESS: {
      return {
        ...state,
        libraryListLoading: false,
        libraryList: [...action.payload.data],
        pageInfo: {
          ...PageInfo,
          ...action.payload.pageInfo,
        },
        error: null,
      };
    }
    case Actions.LIBRARY_LIST_ERROR: {
      return {
        ...state,
        libraryListLoading: false,
        libraryList: [],
        pageInfo: {
          ...PageInfo,
          HasNext: false,
          HasPrevious: false,
        },
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }
    case Actions.LIBRARY_PLAYLIST_LOADING: {
      return {
        ...state,
        libraryItemWithPlayListLoading: true,
        libraryItemWithPlayList: null,
        error: null,
      };
    }
    case Actions.LIBRARY_PLAYLIST_SUCCESS: {
      return {
        ...state,
        libraryItemWithPlayListLoading: false,
        libraryItemWithPlayList: action.payload,
        error: null,
      };
    }
    case Actions.LIBRARY_PLAYLIST_ERROR: {
      return {
        ...state,
        libraryItemWithPlayListLoading: false,
        libraryItemWithPlayList: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message,
        },
      };
    }

    default: {
      return {
        ...state,
        isLoading: false,
        libraryListLoading: false,
      };
    }
  }
};
