import { api, utils } from "@mb";

const diaryService = {
  fetchDailyDiary: async (query) =>
    api.get(
      `api/diary/${query.sectionId}/date/${
        utils.isEmpty(query.date) ? "" : query.date
      }`
    ),
};

export default diaryService;
