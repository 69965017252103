import * as common from "../actions/Common";
import * as Actions from "../actions/";
import dashboardSvc from "appServices/dashboardService";
import quizService from "appServices/quizService";

export {
  getBasicProfile,
  getEvents,
  getNotifications,
  getTimetableContent,
  getQuizInfoForAdmissionTest,
  getSubmittedDocumentsForAdmission,
  submitDocumentForAdmission,
  fetchElectronicContentRecommendations,
};

const fetchElectronicContentRecommendations = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EC_RECOMMENDATIONS_LOADING,
    Actions.EC_RECOMMENDATIONS_SUCCESS,
    Actions.EC_RECOMMENDATIONS_ERROR,
    dashboardSvc.getElectronicContentRecommendations,
    query
  );

const getSubmittedDocumentsForAdmission = () => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.DB_CHECKLIST_FOR_ADMISSION_LOADING,
    Actions.DB_CHECKLIST_FOR_ADMISSION_SUCCESS,
    Actions.DB_CHECKLIST_FOR_ADMISSION_ERROR,
    dashboardSvc.getSubmittedDocumentsForAdmission
  );
};

const submitDocumentForAdmission = (query) => async (dispatch) => {
  await common.thunkHelper(
    dispatch,
    Actions.DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVING,
    Actions.DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVED,
    Actions.DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVE_ERROR,
    dashboardSvc.submitDocumentsForAdmission,
    query
  );
};
const getBasicProfile = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.DB_NOTIFICATION_LIST_LOADING,
    Actions.DB_NOTIFICATION_LIST_SUCCESS,
    Actions.DB_NOTIFICATION_LIST_ERROR,
    dashboardSvc.getBasicProfile
  );
const getEvents = (fromDate, toDate) => async (dispatch) => {
  dispatch(common.fetchStart(Actions.DB_EVENT_LIST_LOADING));
  dashboardSvc
    .getEventList(fromDate, toDate)
    .then((eventList) => {
      return dispatch({
        type: Actions.DB_EVENT_LIST_SUCCESS,
        eventList,
      });
    })
    .catch((error) => {
      debugger;
      console.error(`Error****:`, error);
      dispatch(common.fetchError(error));
    });
};

const getNotifications = (filter) => async (dispatch) => {
  filter.notificationTypeId = 1;
  return await common.thunkHelper(
    dispatch,
    Actions.DB_NOTIFICATION_LIST_LOADING,
    Actions.DB_NOTIFICATION_LIST_SUCCESS,
    Actions.DB_NOTIFICATION_LIST_ERROR,
    dashboardSvc.getNotifications,
    filter
  );
};
const getTimetableContent = (filter) => async (dispatch) => {
  filter.notificationTypeId = 2;
  return await common.thunkHelper(
    dispatch,
    Actions.DB_TIMETABLE_CONTENT_LOADING,
    Actions.DB_TIMETABLE_CONTENT_SUCCESS,
    Actions.DB_TIMETABLE_CONTENT_ERROR,
    dashboardSvc.getTimetable,
    filter
  );
};

const getQuizInfoForAdmissionTest = () => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.DB_QUIZ_ITEM_FOR_ADMISSION_LOADING,
    Actions.DB_QUIZ_ITEM_FOR_ADMISSION_SUCCESS,
    Actions.DB_QUIZ_ITEM_FOR_ADMISSION_ERROR,
    quizService.getQuizInfoForAdmissionTest
  );
/*
const recentActivity = [
  {
    id: 1,
    day: "Today",
    tasks: [
      {
        id: 1,
        name: "Hafiz Ahmed",
        title: "Hafiz Ahmed of class VI completed Hifz",
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      },
      {
        id: 2,
        name: "Musa Khan",
        title: "Musa Khan presented on Tajweed",
        avatar: "https://via.placeholder.com/150x150",
        imageList: [],
      },
    ],
  },
  {
    id: 2,
    day: "Yesterday",
    tasks: [
      {
        id: 5,
        name: "Exams",
        title: "Exams started",
        avatar: "",
        imageList: [
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
          "https://via.placeholder.com/150x150",
        ],
      },
    ],
  },
];
function getRecentActivities() {
  return async (dispatch) => {
    return dispatch({
      type: Actions.DB_RECENT_ACTIVITIES,
      recentActivity: recentActivity,
    });
    //   dispatch(common.fetchStart());
    //   dashboardSvc
    //     .getEventList()
    //     .then(eventList => {
    //       dispatch(common.fetchSuccess);
    //       return dispatch({
    //         type: Actions.DB_EVENT_LIST_SUCCESS,
    //         eventList
    //       });
    //     })
    //     .catch(error => {
    //       debugger;
    //       console.error(`Error****:`, error);
    //       dispatch(common.fetchError(error));
    //     });
  };
}

function getIntroInfo(grNo) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.DB_INTRO_LOADING));

      const result = await dashboardSvc.getIntroUrl(grNo);
      return dispatch(
        common.fetchSuccessWithData(Actions.DB_INTRO_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.DB_INTRO_ERROR));
    }
  };
}

function submitIntroWatched(grNo) {
  return async (dispatch) => {
    try {
      dispatch(common.fetchStart(Actions.DB_INTRO_WATCHED_LOADING));

      const result = await dashboardSvc.submitIntroWatched(grNo);
      return dispatch(
        common.fetchSuccessWithData(Actions.DB_INTRO_WATCHED_SUCCESS, result)
      );
    } catch (error) {
      debugger;
      return dispatch(common.fetchError(error, Actions.DB_INTRO_WATCHED_ERROR));
    }
  };
}
*/
