export const DB_BASIC_PROFILE_ERROR = "DB_BASIC_PROFILE_ERROR";
export const DB_BASIC_PROFILE_SUCCESS = "DB_BASIC_PROFILE_SUCCESS";
export const DB_BASIC_PROFILE_LOADING = "DB_BASIC_PROFILE_LOADING";
export const DB_EVENT_LIST_LOADING = "DB_EVENT_LIST_LOADING";
export const DB_EVENT_LIST_SUCCESS = "DB_EVENT_LIST_SUCCESS";
export const DB_RECENT_LOADING = "DB_RECENT_LOADING";
export const DB_RECENT_ACTIVITIES = "DB_RECENT_ACTIVITIES";
export const DB_INTRO_ERROR = "DB_INTRO_ERROR";
export const DB_INTRO_SUCCESS = "DB_INTRO_SUCCESS";
export const DB_INTRO_LOADING = "DB_INTRO_LOADING";
export const DB_INTRO_WATCHED_LOADING = "DB_INTRO_WATCHED_LOADING";
export const DB_INTRO_WATCHED_SUCCESS = "DB_INTRO_WATCHED_SUCCESS";
export const DB_INTRO_WATCHED_ERROR = "DB_INTRO_WATCHED_ERROR";
export const DB_NOTIFICATION_LIST_LOADING = "DB_NOTIFICATION_LIST_LOADING";
export const DB_NOTIFICATION_LIST_SUCCESS = "DB_NOTIFICATION_LIST_SUCCESS";
export const DB_NOTIFICATION_LIST_ERROR = "DB_NOTIFICATION_LIST_ERROR";
export const DB_TIMETABLE_CONTENT_LOADING = "DB_TIMETABLE_CONTENT_LOADING";
export const DB_TIMETABLE_CONTENT_SUCCESS = "DB_TIMETABLE_CONTENT_SUCCESS";
export const DB_TIMETABLE_CONTENT_ERROR = "DB_TIMETABLE_CONTENT_ERROR";

export const DB_QUIZ_ITEM_FOR_ADMISSION_LOADING = "DB_QUIZ_ITEM_FOR_ADMISSION_LOADING";
export const DB_QUIZ_ITEM_FOR_ADMISSION_SUCCESS = "DB_QUIZ_ITEM_FOR_ADMISSION_SUCCESS";
export const DB_QUIZ_ITEM_FOR_ADMISSION_ERROR = "DB_QUIZ_ITEM_FOR_ADMISSION_ERROR";

export const DB_CHECKLIST_FOR_ADMISSION_LOADING = "DB_CHECKLIST_FOR_ADMISSION_LOADING"
export const DB_CHECKLIST_FOR_ADMISSION_SUCCESS = "DB_CHECKLIST_FOR_ADMISSION_SUCCESS"
export const DB_CHECKLIST_FOR_ADMISSION_ERROR = "DB_CHECKLIST_FOR_ADMISSION_ERROR"
export const DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVING = "DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVING"
export const DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVED = "DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVED"
export const DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVE_ERROR = "DB_CHECKLIST_ITEM_FOR_ADMISSION_SAVE_ERROR";


export const EC_RECOMMENDATIONS_LOADING = "EC_RECOMMENDATIONS_LOADING";
export const EC_RECOMMENDATIONS_SUCCESS = "EC_RECOMMENDATIONS_SUCCESS";
export const EC_RECOMMENDATIONS_ERROR = "EC_RECOMMENDATIONS_ERROR";
