import { Result, Button, Typography } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Paragraph, Text } = Typography;

const Registered = (props) => {
  const consumerCode = `008700200${props?.info?.id || "0000"}`;
  return (
    <div className="gx-page-error-container">
      <div className="">
        <div className="gx-error-code">Great</div>

        <div className="gx-text-center">
          <Result
            status="success"
            title="Thank you for Registering with THE BENCHMARK!"
            subTitle={
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  Your Kuickpay Consumer Number:
                  <Text
                    code
                    mark
                    copyable={{
                      text: consumerCode,
                      tooltips: [
                        "Click here to copy your Kuickpay Consumer Number",
                        "Your Kuickpay Consumer Number Copied!!",
                      ],
                    }}
                  >
                    {consumerCode}
                  </Text>
                  <br /> Follow the Link to Pay now:
                </Text>
              </Paragraph>
            }
            extra={[
              <Button
                type="primary"
                key="kuickpay"
                href="https://app.kuickpay.com/PaymentsBillPayment"
                target="_blank"
              >
                Go to Kuickpay
              </Button>,
              <Button 
              key="home"
              href="http://thebenchmark.com.pk"
              >Go Home</Button>,
            ]}
          >
            <div className="desc">
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  INSTRUCTIONS:
                </Text>
              </Paragraph>
              <Paragraph className="gx-text-left">
                <CheckCircleOutlined /> Click the link above.
              </Paragraph>
              <Paragraph className="gx-text-left">
                <CheckCircleOutlined /> Click the desired bank, read
                instructions and pay via mobile or internet banking.
              </Paragraph>
              <Paragraph className="gx-text-left">
                <ExclamationCircleOutlined /> For queries contact:
                school@thebenchmark.com.pk.
              </Paragraph>
            </div>
          </Result>
        </div>
      </div>
    </div>
  );
};

export default Registered;
