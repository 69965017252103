import * as Actions from "../actions";

const INIT_STATE = {
  isLoading: true,
  alertMessage: "",
  showMessage: false,
  appraisalInfo: null,
  appraisalListLoading:false,
  appraisalList:[],
  error: {
    code: null,
    message: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case Actions.APPRAISALS_LOADING: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }
    case Actions.APPRAISALS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        appraisalInfo: action.payload,
        error: null
      };
    }
    case Actions.APPRAISALS_ERROR: {
      return {
        isLoading: false,
        appraisalInfo: null,
        error: {
          ...state.error,
          code: action.payload.code,
          message: action.payload.message
        }
      };
    }
    case Actions.APPRAISALS_LIST_LOADING: {
      return {
        ...state,
        appraisalListLoading: true,
        error: null
      };
    }
    case Actions.APPRAISALS_LIST_SUCCESS: {
      return {
        ...state,
        appraisalListLoading: false,
        appraisalList: [
          ...action.payload
        ],
        error: null
      };
    }
    default: {
      return {
        ...state,
        isLoading: false
      };
    }
  }
};
