import * as Actions from "../actions";
import * as common from "../actions/Common";

import theService from "appServices/assessmentService";
export { getExamList, getExamDetails, getExamPdf, exampReportDownloaded };

const getExamList = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EXAM_LIST_LOADING,
    Actions.EXAM_LIST_SUCCESS,
    Actions.EXAM_LIST_ERROR,
    theService.getExamList,
    query
  );

const getExamDetails = (examId) => async (dispatch) => {
  try {
    dispatch(common.fetchStart(Actions.EXAM_DETAILS_LOADING));

    const result = await theService.getExamDetails(examId);
    return dispatch(
      common.fetchSuccessWithData(Actions.EXAM_DETAILS_SUCCESS, {
        examId,
        result,
      })
    );
  } catch (error) {
    debugger;
    return dispatch(common.fetchError(error, Actions.EXAM_DETAILS_ERROR));
  }
};

const getExamPdf = (query) => async (dispatch) =>
  await common.thunkHelper(
    dispatch,
    Actions.EXAM_REPORT_LOADING,
    Actions.EXAM_REPORT_SUCCESS,
    Actions.EXAM_REPORT_ERROR,
    theService.getExamPdf,
    query
  );

const exampReportDownloaded = (examId) => async (dispatch) =>
  dispatch(common.fetchSuccess(Actions.EXAM_REPORT_DOWNLOADED));
